// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

body {
  background: rgb(0, 0, 0);
  background-image: radial-gradient(rgba(255, 255, 255, 0.24) 1px, transparent 0);
  background-size: 40px 40px;
  animation: moveBackground 2s linear infinite;
  z-index: -1;
}

body::-webkit-scrollbar {width: 5px;height: 100%;}
body::-webkit-scrollbar-thumb {background-color: rgb(211, 211, 211);}
body::-webkit-scrollbar-track {background-color: rgba(128, 128, 128, 0.33); margin-top: 20px;margin-bottom: 20px;}

@keyframes moveBackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 0 -80px; /* Adjust the value based on the desired speed */
  }
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,iCAAiC;AACnC;;AAEA;EACE,wBAAwB;EACxB,+EAA+E;EAC/E,0BAA0B;EAC1B,4CAA4C;EAC5C,WAAW;AACb;;AAEA,yBAAyB,UAAU,CAAC,YAAY,CAAC;AACjD,+BAA+B,oCAAoC,CAAC;AACpE,+BAA+B,2CAA2C,EAAE,gBAAgB,CAAC,mBAAmB,CAAC;;AAEjH;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,4BAA4B,EAAE,gDAAgD;EAChF;AACF","sourcesContent":["*{\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: \"Roboto\", sans-serif;\n}\n\nbody {\n  background: rgb(0, 0, 0);\n  background-image: radial-gradient(rgba(255, 255, 255, 0.24) 1px, transparent 0);\n  background-size: 40px 40px;\n  animation: moveBackground 2s linear infinite;\n  z-index: -1;\n}\n\nbody::-webkit-scrollbar {width: 5px;height: 100%;}\nbody::-webkit-scrollbar-thumb {background-color: rgb(211, 211, 211);}\nbody::-webkit-scrollbar-track {background-color: rgba(128, 128, 128, 0.33); margin-top: 20px;margin-bottom: 20px;}\n\n@keyframes moveBackground {\n  0% {\n    background-position: 0 0;\n  }\n  100% {\n    background-position: 0 -80px; /* Adjust the value based on the desired speed */\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
